import { ucscTF, ucscATAC, ucscEpi } from "./constants.js";
import DataTable from "datatables.net-bs5";

const csrfToken = document
  .querySelector("meta[name='csrf-token']")
  .getAttribute("content");

const renderViewButton = (data, type) => `
  <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" 
            data-bs-target="#search-modal" data-bs-whatever="${data}" data-bs-type="${type}">
            View
  </button>
`;

// POST method to get csv data for binding/expression data from the python backend.
// See main/views.py:96, main/data_handler.py:109,198 for more information
const fetchDataCSV = async (geneNames, dataType) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    },
    body: JSON.stringify({ gene_names: geneNames, data_type: dataType }),
  };
  const response = await fetch("/csv_data", requestOptions);
  return response.blob();
};

const gatherFilteredTFs = () => {
  // Gather filter values
  const filters = [];
  const filterContainer = document.querySelectorAll("#filter-container .row");
  filterContainer.forEach((element, index) => {
    const tf = element.querySelector('select[name="tfs"]').value;
    const cellType = element.querySelector('select[name="cell_types"]').value;
    // Refer to AlirezaRa94/BloodChIPXtra#13
    // The following line used to read:
    // const condition = index > 0 ? $('select[name="conditions"]', element).val() : '';
    // however, it was edited to remote the ternary condition as we now want to return the
    // condition associated with the first row
    // note that if this change is reverted, then main/utils.py:36 needs to be reverted
    // accordingly.
    const condition = element.querySelector('select[name="conditions"]').value;
    // Check if any variable is null or undefined
    if (tf === null || cellType === null || condition === null) {
      return []; // Return an empty filter list
    }
    // Push the filter condition to the filters array
    filters.push({ condition, tf, cell_type: cellType });
  });
  return JSON.stringify(filters);
};

export const geneTable = new DataTable("#gene-table", {
  dom: 'r<"table-options-wrapper"ipl><"#table-container"t><"table-options-wrapper"ipl>B<"clear">',
  columns: [
    {
      data: null,
      className: "dt-control",
      orderable: false,
      defaultContent: "",
    },
    { data: "id", orderable: false },
    { data: "gene_name" },
    {
      data: null,
      orderable: false,
      render: (data) =>
        `<a href='${ucscTF}${data.chr}:${data.start}-${data.end}' target='_blank'>View</a>`,
    },
    {
      data: null,
      orderable: false,
      render: (data) =>
        `<a href='${ucscEpi}${data.chr}:${data.start}-${data.end}' target='_blank'>View</a>`,
    },
    {
      data: null,
      orderable: false,
      render: (data) =>
        `<a href='${ucscATAC}${data.chr}:${data.start}-${data.end}' target='_blank'>View</a>`,
    },
    { data: "bind_prof", orderable: false },
    {
      data: "gene_name",
      orderable: false,
      render: (data) => renderViewButton(data, "gene_exp"),
    },
    { data: "expr_atlas", orderable: false },
    {
      data: "gene_name",
      orderable: false,
      render: (data) => renderViewButton(data, "tf_summary"),
    },
  ],
  order: [[2, "asc"]],
  stateSave: true,
  serverSide: true,
  processing: true,
  ajax: {
    url: "/gene_data", // URL for fetching data
    type: "POST",
    data: (d) => {
      d.searched_genes = document.getElementById("search-text").value;
      d.tf_filters = gatherFilteredTFs();
    },
    headers: {
      "X-CSRFToken": csrfToken,
    },
  },
  buttons: [
    {
      extend: "csv",
      text: "Export Binding Data",
      action: async (e, dt) => {
        // Get the geneNames from the DataTable
        const geneNames = dt.column(2).data().toArray();

        // Fetch additional data based on columnData
        // See declaration of fetchDataCSV for more information
        const bindingDataCSVBlob = await fetchDataCSV(geneNames, "tf_data");

        // Create a download link and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(bindingDataCSVBlob);
        link.download = "tf_binding_data.csv";
        link.click();
      },
    },
    {
      extend: "csv",
      text: "Export Expression Data",
      action: async (e, dt) => {
        // Get the geneNames from the DataTable
        const geneNames = dt.column(2).data().toArray();

        // Fetch additional data based on columnData
        const expressionDataCSVBlob = await fetchDataCSV(
          geneNames,
          "expression",
        );

        // Create a download link and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(expressionDataCSVBlob);
        link.download = "expression_data.csv";
        link.click();
      },
    },
  ],
});
