const getHeaders = (className) => {
  const elements = document.querySelectorAll(`.${className}`);
  return Array.from(elements, (element) => element.name);
};

const generateHeaders = (allHeaders) => {
  let headers = '<th scope="col">Cell Type</th>';
  allHeaders.forEach((header) => {
    headers += `<th scope="col">${header}</th>`;
  });
  return headers;
};

const fetchTFSummaryData = async (geneName) => {
  const response = await fetch(`/tf_summary/${geneName}`);
  return response.json();
};

const generateCell = (exists) => {
  let icon;
  if (exists === "TRUE") {
    icon =
      '<i class="fa-solid fa-square-check fa-xl" style="color:green !important"></i>';
  } else if (exists === "FALSE") {
    icon =
      '<i class="fa-solid fa-square-xmark fa-xl" style="color:indianred !important"></i>';
  } else {
    icon =
      '<i class="fa-regular fa-square fa-xl" style="color:gray !important"></i>';
  }
  return `<td class="profile-box-container">${icon}</td>`;
};

const generateProfileCells = (TFData, TFs, cellType) => {
  let cells = "";
  TFs.forEach((TF) => {
    TF = TF === "ATAC" ? "A" : TF;
    const curKey = `${cellType}_${TF}`;
    const exists = TFData[curKey];
    const cell = generateCell(exists);
    cells += cell;
  });
  return cells;
};

const generateTableBodyRows = (TFData, TFs, cellTypes) => {
  let tableRows = "";
  cellTypes.forEach((cellType) => {
    const cells = generateProfileCells(TFData, TFs, cellType);
    tableRows += `
      <tr>
          <td>${cellType}</td>
          ${cells}
      </tr>`;
  });

  return tableRows;
};

export const generateTFSummaryTable = async (geneName, modalContent) => {
  const TFSummaryData = await fetchTFSummaryData(geneName);
  const TFs = getHeaders("tf-filter");
  const cellTypes = getHeaders("cell-type-filter");

  const headers = generateHeaders(TFs);
  const tableBody = generateTableBodyRows(TFSummaryData, TFs, cellTypes);
  modalContent.innerHTML = `<div class="table-responsive summary-table-container">
      <table id="summary-table"
         class="table table-striped table-hover table-bordered table-light border-dark text-center"
         style="width:100%">
        <thead class="align-middle">
          <tr>${headers}</tr>
        </thead>
        <tbody>${tableBody}</tbody>
      </table>
    </div>`;
};
