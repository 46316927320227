import Plotly from "plotly.js-dist";

const fetchExpData = async (geneName) => {
  const response = await fetch(`/gene_exp/${geneName}`);
  return response.json();
};

export const createGeneExpressionPlot = async (geneName, modalContent) => {
  const expData = await fetchExpData(geneName);
  const myData = [];
  const plotClasses = {
    HSC: "#f17089",
    MPP: "#cf9131",
    LMPP: "#4F4F4F",
    CLP: "#878787",
    CMP: "#98a539",
    GMP: "#2cada4",
    MEP: "#2eb165",
    Mono: "#4F4F4F",
    Ery: "#878787",
    CD4Tcell: "#4F4F4F",
    CD8Tcell: "#878787",
    Bcell: "#4F4F4F",
    pHSC: "#dcb170",
    LSC: "#72c2e0",
    Blast: "#bcaed5",
    "RUNX1-RUNX1T1": "#4F4F4F",
    "CBFB-MYH11": "#878787",
    NPM1_mutated: "#4F4F4F",
    "FLT3-ITD": "#878787",
    "MLLT3-KMT2A": "#4F4F4F",
    KMT2A_re: "#878787",
    TP53_mutated: "#4F4F4F",
    "GATA2-MECOM": "#878787",
    BEAT_AML: "#434343",
    AML_cell_line: "#1A1A1A",
  };

  Object.entries(plotClasses).forEach(([plotClass, color]) => {
    myData.push({
      y: expData[plotClass],
      type: "box",
      name: plotClass,
      boxpoints: "outliers",
      marker: {
        color: color,
      },
    });
  });

  const layout = {
    title: `Relative Expression of ${geneName}`,
    yaxis: {
      title: {
        text: "Relative expression (log1p cpm)",
        font: {
          family: "Courier New, monospace",
          size: 18,
          color: "#7f7f7f",
        },
      },
    },
  };
  modalContent.innerHTML = "";
  Plotly.newPlot("modal-body-content", myData, layout);
};

const exportButton = document.querySelector("#modal-export-button");

if (exportButton) {
  exportButton.addEventListener("click", () => {
    Plotly.toImage("modal-body-content", {
      format: "png",
      width: 1200,
      height: 800,
      scale: 3.0,
    }).then(async (url) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const geneName = exportButton.getAttribute("data-bs-whatever");
      canvas.width = 3600;
      canvas.height = 2400;

      // Load and draw the exported plot on the canvas
      const plotImage = new Image();
      plotImage.src = url;
      await plotImage.decode();
      ctx.drawImage(plotImage, 0, 0); // Adjust the position of the plot image

      // Load and draw the logo on the canvas
      const logo = new Image();
      logo.src = document.querySelector(".logo").getAttribute("data-logo-url");
      await logo.decode();
      ctx.drawImage(logo, 20, 20, 300, 43); // Adjust the logo size and position

      // Convert the canvas to a data URL (PNG format)
      const finalImageURL = canvas.toDataURL("image/png");

      // Trigger a download for the image
      const a = document.createElement("a");
      a.href = finalImageURL;
      a.download = `${geneName} expression.png`;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  });
}
