// App initialization code goes here\
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "datatables.net-buttons-bs5";
import "datatables.net-buttons/js/buttons.html5.mjs";

import { generateProfileTable } from "./profiles_table.js";
import { geneTable } from "./gene_table.js";
import { generateTFSummaryTable } from "./summary_table.js";
import { createGeneExpressionPlot } from "./expression_plot.js";

const searchButton = document.querySelector(".search-button");
const clearButton = document.querySelector(".clear-button");
const searchInput = document.querySelector("#search-text");

searchButton.addEventListener("click", () => geneTable.draw());
clearButton.addEventListener("click", () => (searchInput.value = ""));

// Add event listener for opening and closing details
geneTable.on("click", "td.dt-control", async (e) => {
  const tr = e.target.closest("tr");
  const row = geneTable.row(tr);

  if (row.child.isShown()) {
    // This row is already open - close it
    row.child.hide();
  } else {
    // Open this row
    await generateProfileTable(row);
  }
});

const addFilterButton = document.querySelector("#add-filter");
const filterContainer = document.querySelector("#filter-container");
const filterTemplate = document.querySelector("#filter-template");
const filterForm = document.querySelector("#filter-form");

addFilterButton.addEventListener("click", () => {
  // Clone the filter template and append it to the container
  const filterRow = filterTemplate.cloneNode(true);
  filterRow.classList.remove("d-none");
  filterRow.classList.add("filter-row");
  filterRow.removeAttribute("id");
  filterContainer.appendChild(filterRow);

  const rows = filterContainer.querySelectorAll(".filter-row");
  const firstCol = filterRow.querySelector(".col-md-3");

  // Add a dropdown for AND/OR/NOT except for the first row
  if (rows.length > 1) {
    const conditionDropdown = `
      <select class="form-select form-select-sm" name="conditions" required>
        <option disabled selected value="">Condition</option>
        <option value="AND">AND</option>
        <option value="OR">OR</option>
        <option value="NOT">NOT</option>
      </select>
    `;
    firstCol.innerHTML = conditionDropdown;
  } else {
    // Add a dropdown for AND/NOT at the first row
    const conditionDropdown = `
      <select class="form-select form-select-sm" name="conditions" required>
        <option disabled selected value="">Condition</option>
        <option value="AND">AND</option>
        <option value="NOT">NOT</option>
      </select>
    `;
    firstCol.innerHTML = conditionDropdown;
  }
});

// Handle "Remove" button click for dynamically added filters
filterContainer.addEventListener("click", (e) => {
  const removeButton = e.target.closest(".remove-filter");
  if (removeButton) {
    const filterRow = removeButton.closest(".filter-row");
    filterRow.remove();
  }
});

// Handle form submission
filterForm.addEventListener("submit", (e) => {
  e.preventDefault();
  const form = e.target;

  if (!form.checkValidity()) {
    form.reportValidity();
  }

  geneTable.draw();
});

const modal = document.querySelector("#search-modal");

if (modal) {
  modal.addEventListener("show.bs.modal", async (event) => {
    // Button that triggered the modal
    const button = event.relatedTarget;

    // Extract gene name and type from data-bs-* attributes
    const geneName = button.getAttribute("data-bs-whatever");
    const buttonType = button.getAttribute("data-bs-type");

    const modalTitle = document.querySelector(".modal-title");
    const exportButton = document.querySelector("#modal-export-button");
    const modalContent = document.querySelector("#modal-body-content");

    modalContent.innerHTML = `<div class="row justify-content-center">
        <div class="col-auto">
            <button class="btn btn-primary" type="button" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
        </div>
      </div>`;
    exportButton.classList.add("d-none");

    if (buttonType === "gene_exp") {
      modalTitle.textContent = `Relative Expression of ${geneName}`;
      await createGeneExpressionPlot(geneName, modalContent);
      exportButton.classList.remove("d-none");
      exportButton.setAttribute("data-bs-whatever", `${geneName}`);
    } else if (buttonType === "tf_summary") {
      modalTitle.textContent = `Transcription Factor Binding, Chromatin Accessibility, and Histone Modification at the ${geneName} locus`;
      await generateTFSummaryTable(geneName, modalContent);
    }
  });
}
