import { ucscTF, ucscATAC, ucscEpi } from "./constants.js";

const getCheckedHeaders = (className) => {
  const elements = document.querySelectorAll(`.${className}:checked`);
  return Array.from(elements, (element) => element.name);
};

const fetchTFData = async (geneName) => {
  const response = await fetch(`/gene_tf/${geneName}`);
  return response.json();
};

const generateHeaders = (TFs, cellTypes) => {
  let mainHeaders = `
    <th scope="colgroup" colspan="3">UCSC</th>
    <th scope="col" rowspan="2">chr</th>
    <th scope="col" rowspan="2">Start</th>
    <th scope="col" rowspan="2">End</th>
  `;
  let subHeaders = `
    <th scope="col">TF</th>
    <th scope="col">Epi</th>
    <th scope="col">ATAC</th>
  `;
  TFs.forEach((TF) => {
    mainHeaders += `<th scope="colgroup" colspan="${cellTypes.length}">${TF}</th>`;
    cellTypes.forEach((c) => {
      subHeaders += `<th scope="col">${c[0]}</th>`;
    });
  });
  return { mainHeaders, subHeaders };
};

const generateProfileCell = (exists) => {
  let color;
  if (exists === "FALSE") {
    color = "blue";
  } else if (exists === "TRUE") {
    color = "red";
  } else {
    color = "grey";
  }
  return `<td class="profile-box-container"><span class="profile-box ${color}-profile"></span></td>`;
};

const generateProfileCells = (atac, TFs, cellTypes) => {
  let profileCells = "";
  TFs.forEach((TF) => {
    cellTypes.forEach((cellType) => {
      TF = TF === "ATAC" ? "A" : TF;
      const curKey = `${cellType}_${TF}`;
      const exists = atac[curKey];
      const profileCell = generateProfileCell(exists);
      profileCells += profileCell;
    });
  });
  return profileCells;
};

const generateTableBodyRows = (atacInfo, TFs, cellTypes) => {
  let tableRows = "";
  atacInfo.forEach((atac) => {
    const atacData = `
      <td><a href='${ucscTF}${atac.chr}:${atac.start}-${atac.end}' target='_blank'>View</a></td>
      <td><a href='${ucscEpi}${atac.chr}:${atac.start}-${atac.end}' target='_blank'>View</a></td>
      <td><a href='${ucscATAC}${atac.chr}:${atac.start}-${atac.end}' target='_blank'>View</a></td>
      <td>${atac.chr}</td>
      <td>${atac.start}</td>
      <td>${atac.end}</td>
    `;

    const cells = generateProfileCells(atac, TFs, cellTypes);
    tableRows += `<tr>${atacData}${cells}</tr>`;
  });

  return tableRows;
};

export const generateProfileTable = async (row) => {
  const geneName = row.data().gene_name;
  const TFs = getCheckedHeaders("tf-filter");
  const cellTypes = getCheckedHeaders("cell-type-filter");
  const { mainHeaders, subHeaders } = generateHeaders(TFs, cellTypes);
  row
    .child(
      `<div class="table-responsive profiles-table-container">
        <table id="profiles-table-${geneName}"
           class="table table-striped table-bordered table-light border-dark text-center"
           style="width:100%">
          <thead class="align-middle">
            <tr>${mainHeaders}</tr>
            <tr>${subHeaders}</tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="${TFs.length * cellTypes.length + 6}">
                <button class="btn btn-primary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>`,
    )
    .show();

  const TFData = await fetchTFData(geneName);
  const tableBody = document.querySelector(`#profiles-table-${geneName} tbody`);
  tableBody.innerHTML = generateTableBodyRows(TFData, TFs, cellTypes);
};
